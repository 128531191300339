
import Axios, { Canceler } from 'axios';
import { API_ROUTE, BASE, buildGetFetch } from './base';
import {User} from '../types/user';
import { NotificationService } from './notificationservice';
import { InviteService } from './inviteservice';
import { ROLES } from '../types/enums';
import { UnreadMessagesService } from './messageservice';
import { RemindersService } from './reminderservice';
import { clearRecentExams, RecentExams } from './recents';

export interface LoginData {
    user: User;
    exam_cost: number;
    invites: number;
    unreadMessages: number;
    reminders: number;
    roles: Array<{
        role_id: ROLES;
    }>;
    payment_service: any;
}

interface IAuth {
    /**
     * Start application
     */
    start: () => Promise<any>;
    login: (email: string, password: string) => Promise<any>;
    logout: () => void;
    user: () => User | null;
    exam_cost: () => number;
    availableBudget: () => number;
    sync: (onSync: (success: boolean) => void, onLoad: (load: boolean) => void, onError: (load: boolean) => void) => [() => Promise<LoginData | null>, Canceler];
    isLoggedIn: () => boolean;
    csrf: () => string;
    canBeInvited: () => boolean;
    paymentService: () => any;
    isPaymentActive:() => boolean;
    hasPaymentWarnings: () => boolean;
    isAdmin: () => boolean;
    isOperator: () => boolean;
    isSpecialist: () => boolean;
    isBaseDoctor: () => boolean;
    isTemporary: () => boolean;
    refreshSession: (data: LoginData) => void;
}

/**
 * TODO quando si aggiorna il token va aggiornato anche in Echo -> Echo.connector.pusher.config.auth.headers['X-CSRF-TOKEN'] = token
 * https://github.com/laravel/echo/issues/252
 */

export const Auth: IAuth = (() => {
    
    Axios.defaults.withCredentials = true;
    Axios.defaults.baseURL = BASE;

    /**
     * Private logged in variable
     */
    let _isLoggedIn = false;
    /**
     * Private user variable
     */
    let _user: User | null;
    /**
     * Private exam cost
     */
    let _exam_cost: number;
    /**
     * could be useful
     */
    let _csrf_token: string = '';

    let _roles: Array<{
        role_id: ROLES;
    }> = [];

    let _payment_service: any = null;

    const setupLogin = (data: LoginData) => {
        if(_isLoggedIn === false && !_user){
            console.info("User logged",data);
            _exam_cost = data.exam_cost;
            _user = data.user;
            _roles = data.roles;
            _payment_service = data.payment_service;
            _isLoggedIn = true;
            clearRecentExams();
            NotificationService.connect(_user.id);
            InviteService.setPendingInvites(data.invites);
            UnreadMessagesService.setUnreadMessages(data.unreadMessages);
            RemindersService.setReminders(data.reminders);
        }
    }
    const setupLogout = () => {
        if(_isLoggedIn === true && _user){
            _user = null;
            _roles = [];
            _payment_service = null;
            _isLoggedIn = false;
            clearRecentExams();
            NotificationService.disconnect();
            InviteService.setPendingInvites(0);
            UnreadMessagesService.setUnreadMessages(0);
            RemindersService.setReminders(0);
        }
    }

    return {
        csrf: () => _csrf_token,
        start: () => {
            return new Promise(async (resolve, reject) => {
                const result = await Axios.get(`/sanctum/csrf-cookie`).catch((reason: any) => {
                    reject(reason);
                    return null;
                });
                if(result){
                    _csrf_token = result.config.headers['X-XSRF-TOKEN'];
                    resolve();
                }
            });
        },
        login: (email: string, password: string) => {
            return new Promise(async (resolve, reject) => {
                const result = await Axios.post<LoginData>(`${API_ROUTE}/login`, {email, password}).catch(() => {
                    reject();
                    return null;
                });
                if(result && result.data){
                    setupLogin(result.data);
                    resolve();
                }
            });
        },
        refreshSession: (data: LoginData) => {
            _user=data.user;
            InviteService.setPendingInvites(data.invites);
            UnreadMessagesService.setUnreadMessages(data.unreadMessages);
            RemindersService.setReminders(data.reminders);
        },
        sync: (onSync: (success: boolean) => void, onLoad: (load: boolean) => void, onError: (load: boolean) => void) => {
            const _sync = (user: LoginData | null) => {
                if(user){
                    console.warn("User is authenticated");
                    setupLogin(user);
                    onSync(true);
                } else {
                    console.warn("User is not authenticated");
                    setupLogout();
                    onSync(false);
                }
            }
            return buildGetFetch<LoginData>('/user', {}, _sync, onLoad, onError);
        },
        logout: () => {
            return new Promise(async (resolve, reject) => {
                await Axios.post(`${API_ROUTE}/logout`, {});
                setupLogout();
                resolve();
            });
        },
        user: (): User | null => {
            return _user;
        },
        exam_cost: (): number => {
            return _exam_cost;
        },
        availableBudget: (): number => {
            if(!_user) return 0;
            return (_user.assigned_budget - _user.used_budget);
        },
        isLoggedIn: () => {
            return _isLoggedIn;
        },
        canBeInvited: () => {
            return true;
            // sembra troppo limitante return _roles.findIndex((r) => r.role_id === ROLES.ADMIN || r.role_id === ROLES.SPECIALIST) >= 0;
        },
        paymentService: () => {
            return _payment_service;
        },
        isPaymentActive: () => {
            if(_payment_service===null) return false;
            if(_payment_service.payment===null) return false;
            if(!_payment_service.active) return false;
            if(_payment_service.end_ts) return false;
            if(_payment_service.payment.family===2) return true; // canone
            return true;
        },
        hasPaymentWarnings:() => {
            if(!_payment_service) return false;
            if(!_payment_service.payment) return false;
            if(_payment_service.payment.family===2) return false; // canone
            if(!_user) return false;
            if(_user.assigned_budget-_user.used_budget<_exam_cost*5) return true;
            return false;
        },
        isAdmin: () => {
            // eslint-disable-next-line
            if(_roles.findIndex((r) => r.role_id == ROLES.ADMIN) >= 0) return true; // amministratore
            return false;
        },
        isSpecialist: () => {
            // eslint-disable-next-line
            if(_roles.findIndex((r) => r.role_id == ROLES.SPECIALIST) >= 0) return true; // specialista
            return false;
        },
        isOperator: () => {
            // eslint-disable-next-line
            if(_roles.findIndex((r) => r.role_id == ROLES.OPERATOR) >= 0) return true; // tecnico sanitario
            return false;
        },
        isBaseDoctor: () => {
            // eslint-disable-next-line
            if(_roles.findIndex((r) => r.role_id == ROLES.BASE_DOCTOR) >= 0) return true; // medico di base
            return false;
        },
        isTemporary: () => {
            if(!_user) return false;
            else return _user.temporary;
        }
    }

})();

